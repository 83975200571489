import { Button, CircularProgress, Stack, TextField } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { config } from "../App";
import Footer from "./Footer";
import Header from "./Header";
import "./Register.css";
import { useHistory, Link } from "react-router-dom";


const Register = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [state, setFormData] = useState({
      username:"",
      password:"",
      confirmPassword:""})

      const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const history = useHistory();

  /**
   * Definition for register handler
   * - Function to be called when the user clicks on the register button or submits the register form
   *
   * @param {{ username: string, password: string, confirmPassword: string }} formData
   *  Object with values of username, password and confirm password user entered to register
   *
   * API endpoint - "POST /auth/register"
   *
   * Example for successful response from backend for the API call:
   * HTTP 201
   * {
   *      "success": true,
   * }
   *
   * Example for failed response from backend for the API call:
   * HTTP 400
   * {
   *      "success": false,
   *      "alert "Username is already taken"
   * }
   */


  const register = async (state) => {
    if(validateInput(state) === true){
      let username = state.username;
      let password = state.password;
      setIsLoading(true);
      await axios.post(`${config.endpoint}/auth/register`, {
        username: username,
        password: password
    })
    .then((response) => {
      console.log(response.data)
      setIsLoading(false);
      if(response.data.success === true){
        enqueueSnackbar("Registered successfully",{variant:"success"})
        history.push("/login")
      }
    }).catch(err => {
       console.log(err.response.data)
       setIsLoading(false);
       enqueueSnackbar(err.response.data.message,{variant:"error"})
       });
    }else{
      setIsLoading(false);
      console.log("Rejected")
    }   
  };

  // TODO: CRIO_TASK_MODULE_REGISTER - Implement user input validation logic
  /**
   * Validate the input values so that any bad or illegal values are not passed to the backend.
   *
   * @param {{ username: string, password: string, confirmPassword: string }} data
   *  Object with values of username, password and confirm password user entered to register
   *
   * @returns {boolean}
   *    Whether validation has passed or not
   *
   * Return false if any validation condition fails, otherwise return true.
   * (NOTE: The error messages to be shown for each of these cases, are given with them)
   * -    Check that username field is not an empty value - "Username is a required field"
   * -    Check that username field is not less than 6 characters in length - "Username must be at least 6 characters"
   * -    Check that password field is not an empty value - "Password is a required field"
   * -    Check that password field is not less than 6 characters in length - "Password must be at least 6 characters"
   * -    Check that confirmPassword field has the same value as password field - Passwords do not match
   */
  const validateInput = (formData) => {
    let username = formData.username;
    let password = formData.password;
    let confirmPassword = formData.confirmPassword

    if(username === "" ){
      enqueueSnackbar("Username is required",{variant:"warning"});
      return false;
    }
    if(username.length <6){
      enqueueSnackbar("Username must be at least 6 characters",{variant:"warning"});
      return false;
    }
    
    if(password === ""){
      enqueueSnackbar("Password is required",{variant:"warning"});
      return false;
    
    }
    if(password.length < 6){
      enqueueSnackbar("Password must be at least 6 characters",{variant:"warning"});
      return false;
    }

    if(password !== confirmPassword){
      enqueueSnackbar("Passwords do not match",{variant:"warning"});
      return false;
    }else{
      console.log(4)
      return true;
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      minHeight="100vh"
    >
      <Header hasHiddenAuthButtons />
      <Box className="content">
        <Stack spacing={2} className="form" >
          <h2 className="title">Register</h2>
          <TextField
            id="username"
            label="Username"
            variant="outlined"
            title="Username"
            name="username"
            placeholder="Enter Username"
            fullWidth     
            value={state.username}
            onChange={handleChange}
            
          />
          <TextField
            id="password"
            variant="outlined"
            label="Password"
            name="password"
            type="password"
            helperText="Password must be atleast 6 characters length"
            fullWidth
            placeholder="Enter a password with minimum 6 characters"
            value={state.password}
            onChange={handleChange}
            
          />
          <TextField
            id="confirmPassword"
            variant="outlined"
            label="Confirm Password"
            name="confirmPassword"
            type="password"
            fullWidth
            value={state.confirmPassword}
            onChange={handleChange}

          />
           
           {isLoading?<Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress color="primary" value={25} />
          </Box>
          :
           <Button className="button" variant="contained" type="submit" onClick={() => register(state)}>
            Register Now
           </Button>
}


          <p className="secondary-action">
            Already have an account?{" "} 
            <Link className="link" to="/login">
              Login here
             </Link> 
          </p>
        </Stack>
      </Box>
      <Footer />
    </Box>
  );
};

export default Register;
